import React, { FC } from "react"
import LangContext from "@contexts/lang"
import { Helmet } from "react-helmet"
import favicon from "@images/favicon.ico"
import formatMessage from "@utils/texts"
import { useStaticQuery, graphql } from 'gatsby';

type Props = {
  title?: string
  description?: string
  location?
  meta?
  image?
}

const SEO: FC<Props> = ({ children, image, title, description, location, meta = [] }) => {
  const { lang } = React.useContext(LangContext)
  const default_og_image_url = 'https://cdn.sanity.io/images/lg38muzm/production/3469e869be7712783001c44765da3ab3965ffdfb-1368x881.png?w=800&h=515&fit=crop'
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          twitter
        }
      }
    }
  `);

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${formatMessage({ id: "zyda", lang })}`}
      meta={[
        {
          name: `description`,
          content: description ? description : site.siteMetadata.description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: site?.siteMetadata?.description,
        },
        {
          property: `og:url`,
          content: location?.href,
        },
        {
          property: `fb:app_id`,
          content: `846583795969804`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:site`,
          content: site.siteMetadata.twitter,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description ? description : site.siteMetadata.description,
        },
      ].concat(meta)}
    >
      <html dir={lang === "ar" ? "rtl" : "ltr"} />
      <title>{title}</title>
      <link rel="icon" href={favicon} />
      <link rel="canonical" href={location.href} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta charSet="utf-8" />
      <meta name="description" content={site.siteMetadata.description} />
      {location && <meta property="og:url" content={location.href} />}
      <meta property="og:image" content={image || default_og_image_url} />
      <meta property="og:title" content={title} key="ogtitle" />
      <meta
        property="og:site_name"
        content={site.siteMetadata.title}
        key="ogsitename"
      />
      <meta property="og:type" content="website" />
      <meta property="fb:app_id" content="846583795969804" />
      <meta property="og:description" content={site?.siteMetadata?.description} key="ogdesc" />
      {children}
    </Helmet>
  )
}

export default SEO
